import { DateTime, HelpText, Image, Loc, Request, ValuePlaceholder, useDrawer } from "@axl/accel-framework/components";
import { useApi } from 'components';
import React, { useContext, useState } from "react";
import { Col, Modal, Row, Skeleton } from "antd";
import { combineClasses } from "@axl/accel-framework/utils";
import { TrophyOutlined } from "@ant-design/icons";
import { GamificationAchievement, GamificationAchievementFilter } from "@axl/accel-framework/models";
import { GamificationCard } from "../../GamificationCard";
import styles from './GamificationAchievement.module.scss';
import { Context } from "@axl/accel-framework/components/AccelProvider/AccelProvider";


export const GamificationAchievementPreview: React.FC<{
    achievement: GamificationAchievement;
    headerExtra?: React.ReactNode;
    hideDate?: boolean;
    hideInfo?: boolean;
}> = ({ achievement, headerExtra, hideDate, hideInfo }) => {
    const { loc } = useContext(Context);

    return <div className={combineClasses('flex align-center gap-15 gamification-achievement', styles.achievement)}
        data-size={'large'}
        data-achieved={achievement.achievedDate != null}>
        <div className="relative">
            <Image fit="fill" cloudKey={achievement.imageCloudKey} width={64} height={64} className='gamification-achievement__image br-xxs' resize={{ h: 64, w: 64 }} />
            {achievement.count > 1 && <div className="absolute bg-white br-full p-3 border-primary border fs-10 flex flex-center fw-500 color-black z-2" style={{ right: -5, top: -5, width: 20, height: 20 }}>x{achievement.count}</div>}
        </div>
        <div className='flex flex-col flex-1 line-clamp-3'>
            {headerExtra}
            <div className="fw-600 color-gray-9 ellipsis">
                <ValuePlaceholder
                    value={achievement.title}
                    placeholder={loc.word(`Gamification.achievement.subtype.${achievement.subtype}`, { default: achievement.subtype })}
                />
                {hideInfo !== true && <>
                    &nbsp;
                    {achievement.type != 'custom' && <HelpText tooltip label={<Loc word={`Gamification.achievement.executionMethod.${achievement.executionMethod}`} default={achievement.executionMethod} />} />}
                </>}
            </div>
            {(achievement.description || achievement.subtype) && <div className="fs-12 color-gray-8 lh-15">
                <ValuePlaceholder
                    value={achievement.description}
                    placeholder={loc.word(`Gamification.achievement.subtype.${achievement.subtype}.desc`, { default: achievement.subtype })}
                />
            </div>}
            {(achievement.achievedDate && hideDate !== true) && <div className="fs-12 color-gray-8">
                <Loc word='Gamification.achievement.achievedOn' default='Achieved on' />&nbsp;
                <DateTime date={achievement.achievedDate!} format='ll' />
            </div>}
        </div>
        <div className={combineClasses('fw-700 color-success fs-24')}>
            +{achievement.points}
        </div>
    </div>;
}

const GamificationAchievementsCardPlaceholder: React.FC = () => {
    return <div className="flex flex-center flex-1 flex-col gap-10 text-placeholder">
        <TrophyOutlined style={{ fontSize: 100 }} />
        <Loc word='Gamification.achievements.placeholder' default='No achievements yet' className="fs-16 fw-500" />
    </div>;
}

export const GamificationAchievementsCard: React.FC = () => {
    const api = useApi();
    const drawer = useDrawer();
    const [filter] = useState(new GamificationAchievementFilter({ take: 3 }));

    return <>
        <GamificationCard header={<Loc word='Gamification.achievements.showAll' default="Show all achievements" />}
            onTitleClick={() => drawer.open()}>
            <div className="flex flex-col flex-1 justify-start gap-5 w-100">
                <Request action={() => api.gamification.fetchAchievements(filter)} auto>
                    {({ data }) => data
                        ? data.items.length > 0
                            ? data.items.map((x, i) => <GamificationAchievementPreview key={i} achievement={x} hideInfo />)
                            : <GamificationAchievementsCardPlaceholder />
                        : <Skeleton active />}
                </Request>
            </div>
        </GamificationCard>

        <Modal open={drawer.visible}
            title={<Loc word='Gamification.achievements.all' default='All achievements' />}
            okButtonProps={{ hidden: true }}
            onCancel={drawer.close}
            width={800}
            destroyOnClose>
            <Request action={() => api.gamification.fetchAchievements(new GamificationAchievementFilter({ useBaseFilter: false, showAll: true }))} auto>
                {({ data }) => data
                    ? data.items.length > 0
                        ? <Row gutter={10}>
                            {data.items.map((x, i) => <Col key={x.id} md={12} xs={24} className="mb-10"><GamificationAchievementPreview achievement={x} /></Col>)}
                        </Row>
                        : <GamificationAchievementsCardPlaceholder />
                    : [0, 1].map(i => <Row key={i} gutter={10}>
                        <Col md={12} xs={24} className="mb-10">
                            <Skeleton avatar={{ shape: 'square', size: 64 }} active paragraph={{ rows: 0 }} />
                        </Col>
                        <Col md={12} xs={24} className="mb-10">
                            <Skeleton avatar={{ shape: 'square', size: 64 }} active paragraph={{ rows: 0 }} />
                        </Col>
                    </Row>)}
            </Request>
        </Modal>
    </>;
}
