import React, { useCallback, useContext, useEffect } from 'react';
import { notification } from 'antd';
import { observer } from 'mobx-react'
import './GamificationAchievementNotification.scss';
import { NotificationProps } from '../../NotificationProps';
import { GamificationAchievementPayload } from 'hub-client/payloads/notifications';
import { GamificationAchievementPreview } from 'views/gamification/pages/components/GamificationAchievementsCard/GamificationAchievementsCard';
import { Context } from '@axl/accel-framework/components/AccelProvider/AccelProvider';
import confetti from 'canvas-confetti';

type Props = NotificationProps & {
    payload: GamificationAchievementPayload;
}
const GamificationAchievementNotification: React.FC<Props> = ({ payload, onClose }) => {
    const ctx = useContext(Context);
    const [api, contextHolder] = notification.useNotification();

    // it's important to dont put `payload` into deps, because every new acvievement will close prev notification
    useEffect(() => {
        api.open({
            key: payload.key,
            className: 'game_achievement_notification',
            message: null,
            duration: 8,
            placement: 'bottom',
            onClose: () => onClose(),
            description: <>
                <GamificationAchievementPreview achievement={payload.achievement}
                    hideDate
                    hideInfo
                    headerExtra={<span className='fw-500'>{ctx.loc.word('Gamification.newAchievement', { default: 'New Achievement' })}:</span>} />
            </>
        });
        fireConfetti();
        return () => {
            notification.close(payload.key);
        };
    }, [api]);

    const fireConfetti = useCallback(() => {
        const count = 200;
        function fire(particleRatio: number, opts: confetti.Options) {
            confetti({
                ...opts,
                particleCount: Math.floor(count * particleRatio),
                origin: {
                    x: 0.5,
                    y: 0.96
                },
                gravity: 0.4,
                drift: 0.15,
                ticks: 300
            });
        }

        fire(0.25, {
            spread: 26,
            startVelocity: 55,
        });
        fire(0.2, {
            spread: 60,
        });
        fire(0.35, {
            spread: 100,
            decay: 0.91,
            scalar: 0.8
        });
        fire(0.1, {
            spread: 120,
            startVelocity: 25,
            decay: 0.92,
            scalar: 1.2
        });
        fire(0.1, {
            spread: 120,
            startVelocity: 45,
        });
    }, []);

    return <>{contextHolder}</>;
}
export default observer(GamificationAchievementNotification);